









import Vue from 'vue';
import Component from 'vue-class-component';
import PageLayout from '@/components/page/layout.vue';
import TableGenerator, { ITableColumn } from '@/components/tables/generator.vue';
import TableNavigation from '@/components/tables/navigation.vue';
import AreaForm from '@/components/areas/areas/form/index.vue';
import { AreaTriggersModule } from '@/store/areas/triggers';
import { areaTriggersModule, areaRecordsModule } from '@/store';
import { TableComponentNames } from '@/components/tables/components/names';

const schema: ITableColumn[] = [
  { label_i18n: 'id', prop: 'id', 'class-name': 'events-table__id-cell', width: 80 },
  { label_i18n: 'image', prop: '', component: TableComponentNames.AreaTriggerScreenshot, width: 160 },
  { label_i18n: 'description', prop: '', component: TableComponentNames.AreaTriggerDescription, 'min-width': 240 },
  { label_i18n: 'created', prop: 'created_date', component: TableComponentNames.DateTime, width: 160 }
];

@Component({
  components: { AreaForm, TableNavigation, PageLayout, TableGenerator }
})
export default class AreaTriggers extends Vue {
  get state(): AreaTriggersModule {
    return areaTriggersModule;
  }

  get schema(): ITableColumn[] {
    return schema;
  }

  mounted() {
    this.state.setAutoUpdate(true);
  }

  beforeDestroy() {
    this.state.setAutoUpdate(false);
  }

  rowClickHandler({ item }) {
    const route = areaRecordsModule.getListRoute({ area_trigger: [item.id] });
    this.$router.push(route);
  }
}
