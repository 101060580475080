import { IFormSchema } from '@/definitions/base.form.schema';

const schema: IFormSchema = {
  fields: [
    {
      prop: 'name',
      i18nLabel: 'name',
      component: {
        name: 'input'
      },
      validators: [
        {
          required: true,
          props: {
            i18nMessage: 'error.required.field'
          }
        }
      ]
    },
    {
      prop: 'cameras',
      i18nLabel: 'cameras',
      component: {
        name: 'cameras',
        i18nPlaceholder: 'cameras',
        itemsPath: '$store.state.cameras.items',
        multipleLimit: 1
      },
      validators: [
        {
          required: true,
          name: 'array',
          props: {
            i18nMessage: 'error.array.empty',
            minLength: 1
          }
        }
      ]
    },
    {
      prop: 'camera_roi',
      i18nLabel: 'roi',
      component: {
        name: 'camera-regions',
        camerasProp: 'cameras',
        itemsPath: '$store.state.cameras.items'
      }
    },
    {
      prop: '',
      i18nLabel: 'area_trigger',
      component: {
        name: 'area-trigger'
      }
    },
    {
      prop: '',
      i18nLabel: 'area_calendar',
      component: {
        name: 'area-calendar'
      }
    }
  ]
};

export default schema;
